<template>
  <v-simple-table class="elevation-0 transparent">
    <template v-slot:default>
      <thead>
        <tr>
          <th
            v-for="(header, idx) in headers"
            :key="idx"
            :class="header.align"
          >
            {{ header.title }}
          </th>
          <th>
            <!-- / -->
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(product, idx) in products"
          :key="idx"
        >
          <td style="width: 50px">
            <v-avatar
              :size="isExpanded ? 150 : 40"
              rounded
              class="elevation-3 my-3"
              style="transition: all 0.2s"
            >
              <v-img
                v-if="product.image"
                :src="$store.state.imagePath + product.image"
              />
              <v-icon
                v-else
                small
                color="primary"
                >fas fa-box</v-icon
              >
            </v-avatar>
          </td>
          <td>{{ product.reference }}</td>
          <td>{{ product.name }}</td>
          <td class="text-center">
            <v-chip
              class="white--text"
              :color="getColor(product.order_product_status)"
              :outlined="product.order_product_status > 400"
              :style="
                product.order_product_status > 400 ? 'border-style: dashed' : ''
              "
              small
            >
              {{ checkStatus(product.order_product_status) }}
            </v-chip>
          </td>
          <td class="text-center">
            <ul>
              <li
                v-for="(item, idx) in product_volumes(product.volumes)"
                :key="idx"
              >
                <div class="caption">{{ item.volume + ' x ' + item.type }}</div>
                <span class="caption grey--text">
                  {{
                    'L-' +
                    (item.width ? item.width : 0) +
                    ' x ' +
                    'A-' +
                    (item.height ? item.height : 0) +
                    ' x ' +
                    'C-' +
                    (item.volume_length ? item.volume_length : 0)
                  }}
                </span>
              </li>
            </ul>
          </td>
          <td class="text-center">{{ calculateWeight(product.volumes) }}</td>
          <td class="text-center">
            <div class="primary--text font-weight-bold">
              Produzidos: {{ totalProduced }}
            </div>
            <div class="grey--text">
              Pedido:
              {{ product.alt_qtty ? product.alt_qtty : product.default_qtty }}
            </div>
          </td>
          <td class="text-right">
            {{ unitaryCost(product) }}
          </td>
          <td class="text-right">
            {{ subtotal(product) }}
          </td>
          <td class="text-right">
            <v-btn
              :disabled="
                (product.order_product_status != 45 &&
                  product.order_product_status != 441 &&
                  product.order_product_status != 451) ||
                !showEditOrders
              "
              @click="addProduct(product)"
              :class="
                product.order_product_status == 45 ||
                product.order_product_status == 441 ||
                product.order_product_status == 451
                  ? 'primary'
                  : 'grey lighten-1'
              "
              icon
              small
              rounded
            >
              <v-icon
                small
                color="white"
                >fas fa-plus</v-icon
              >
            </v-btn>
          </td>
        </tr>
        <tr class="grey lighten-3 secondary--text">
          <td colspan="4"></td>
          <td class="text-center font-weight-bold py-2">
            <div v-if="totalVolumes[0] > 0">Caixas: {{ totalVolumes[0] }}</div>
            <div v-if="totalVolumes[1] > 0">Pacotes: {{ totalVolumes[1] }}</div>
            <div v-if="totalVolumes[2] > 0">Rolos: {{ totalVolumes[2] }}</div>
            <div v-if="totalVolumes[3] > 0">Sacos: {{ totalVolumes[3] }}</div>
            <div v-if="totalVolumes[4] > 0">
              Envelopes: {{ totalVolumes[4] }}
            </div>
            <div v-if="totalVolumes[5] > 0">
              Palletes: {{ totalVolumes[5] }}
            </div>
          </td>
          <td class="text-center font-weight-bold">{{ totalWeight }} kg</td>
          <td colspan="5"></td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    products: Array,
    buttonText: String,
    tableHeader: Array,
    isInvoiced: Boolean,
    isExpanded: Boolean,
  },
  data: () => ({
    quantityValue: [],
    to_invoice_qtty: null,
    headers: [
      { title: 'Imagem', align: '' },
      { title: 'Nome', align: '' },
      { title: 'Código', align: '' },
      { title: 'Status', align: 'text-center' },
      { title: 'Volumes', align: 'text-center' },
      { title: 'Peso (Kg)', align: 'text-center' },
      { title: 'Quantidades', align: 'text-center' },
      { title: 'Preço UN', align: 'text-right' },
      { title: 'Subtotal', align: 'text-right' },
    ],
  }),
  computed: {
    ...mapGetters('comercial', ['showEditOrders']),
    totalWeight() {
      let total = 0
      this.products.map((prod) => {
        prod.volumes.map((vol) => {
          total = total + vol.weight
        })
      })

      return total
    },
    totalProduced() {
      let totalProduced = 0
      this.products.map((prod) => {
        prod.volumes.map((vol) => {
          totalProduced = totalProduced + vol.quantity
        })
      })
      return totalProduced
    },
    totalVolumes() {
      let volumes = [0, 0, 0, 0, 0, 0]

      this.products.map((prod) => {
        prod.volumes.map((vol) => {
          switch (vol.type) {
            case 1:
              volumes[0] = volumes[0] + parseInt(vol.volume)
              break
            case 2:
              volumes[1] = volumes[1] + parseInt(vol.volume)
              break
            case 3:
              volumes[2] = volumes[2] + parseInt(vol.volume)
              break
            case 4:
              volumes[3] = volumes[3] + parseInt(vol.volume)
              break
            case 5:
              volumes[4] = volumes[4] + parseInt(vol.volume)
              break
            case 6:
              volumes[5] = volumes[5] + parseInt(vol.volume)
              break
            default:
              break
          }
        })
      })

      return volumes
    },
  },
  methods: {
    calculateWeight(volumes) {
      if (volumes) {
        return volumes.reduce((acc, curr) => acc + curr.weight, 0)
      }
    },
    quantity(product_quantity, index) {
      return (
        product_quantity +
        ' / ' +
        (parseInt(product_quantity) - parseInt(this.quantityValue[index]))
      )
    },
    addProduct(product) {
      this.$emit('addProductAction', product)
    },
    removeProduct(product) {
      product.invoiced_qtty = 0
      this.$emit('removeProductAction', { product: product })
    },
    unitaryCost(item) {
      if (item.alt_qtty) {
        if (item.alt_unitary.includes(',')) {
          let cost = item.alt_unitary.replace(',', '.')
          cost = parseFloat(cost)
          return cost.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
        } else {
          return parseFloat(item.alt_unitary).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
        }
      } else {
        return parseFloat(item.default_unitary).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })
      }
    },
    subtotal(item) {
      if (item.alt_qtty) {
        if (item.alt_unitary.includes(',')) {
          let cost = item.alt_unitary.replace(',', '.')
          let subtotal = 0

          cost = parseFloat(cost)
          subtotal = cost * item.alt_qtty
          return subtotal.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
        } else {
          return parseFloat(item.alt_unitary * item.alt_qtty).toLocaleString(
            'pt-BR',
            { style: 'currency', currency: 'BRL' }
          )
        }
      } else {
        return parseFloat(
          item.default_unitary * item.default_qtty
        ).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      }
    },
    getVolumeType(type) {
      switch (type) {
        case 1:
          return 'Caixa'
        case 2:
          return 'Pacote'
        case 3:
          return 'Rolo'
        case 4:
          return 'Saco'
        case 5:
          return 'Envelope'
        case 6:
          return 'Pallete'
      }
    },
    product_volumes(volumes) {
      if (volumes.length == 0) return [{ type: '-', volume: '-' }]

      let volumesArr = []

      volumes.map((vol) => {
        let { height, width, volume_length, volume, type } = vol

        let volume_type = this.getVolumeType(type)

        volumesArr.push({
          type: volume_type,
          height,
          width,
          volume_length,
          volume,
        })
      })

      return volumesArr
    },
    checkStatus(status) {
      switch (status) {
        case 41:
          return 'Aguardando compra'
        case 411:
          return 'Parcialmente comprado'
        case 42:
          return 'Aguardando gravação'
        case 421:
          return 'Parcialmente gravado'
        case 43:
          return 'Aguardando conferência'
        case 431:
          return 'Parcialmente conferido'
        case 44:
          return 'Aguardando produção'
        case 441:
          return 'Parcialmente produzido'
        case 45:
          return 'Aguardando faturamento'
        case 451:
          return 'Parcialmente faturado'
        case 46:
          return 'Aguardando finalização'
        case 47:
          return 'Finalizado'
      }
      return status
    },
    getColor(status) {
      switch (status) {
        case 41:
          return 'blue-grey'
        case 411:
          return 'blue-grey'
        case 42:
          return 'indigo'
        case 421:
          return 'indigo'
        case 43:
          return 'cyan'
        case 431:
          return 'cyan'
        case 44:
          return 'deep-purple'
        case 441:
          return 'deep-purple'
        case 45:
          return 'orange lighten-2'
        case 451:
          return 'orange'
        case 46:
          return 'teal'
        case 47:
          return 'success'
      }
      return status
    },
  },
}
</script>

<style></style>
